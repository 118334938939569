<template>
  <div class="card border-left-primary shadow h-100">
    <div class="card-header">
      <div class="d-sm-flex align-items-center justify-content-between w-100">
        <div class=" font-weight-bold text-secondary text-uppercase">
          Filter
        </div>
      </div>
    </div>

    <div class="card-body">
      <div v-for="val in activeFilters" :key="val.key">
        <FilterCell
          :filter="val"
          @delete="removeFilter(val)"
          @change="newVal => modifyFilter(val, newVal)"
        ></FilterCell>
      </div>
    </div>
  </div>
</template>
<script>
import FilterCell from "./FilterCell"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "FilterCard",
  components: { FilterCell },
  computed: {
    ...mapGetters({ activeFilters: "squaapiStore/getQueryFilters" })
  },
  methods: {
    ...mapActions({
      removeFilter: "squaapiStore/removeFilter",
      modifyFilter: "squaapiStore/modifyFilter"
    })
  }
}
</script>
