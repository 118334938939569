<template>
  <div class="card shadow mb-4">

    <!-- Card Header - Dropdown -->
    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">Chart</h6>
      <div class="dropdown no-arrow">
        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
             aria-labelledby="dropdownMenuLink">
          <div class="dropdown-header">Dropdown Header:</div>
          <button class="dropdown-item" v-for="(val, index) in chartOptions"
                  :key="index" @click="changeChart(val)">{{ val }}
          </button>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </div>
    </div>

    <!-- Card Body -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <div id="chart-1" style="height: 300px;"></div>
        </div>
        <div class="col-3 p-1">
          <b-form-group
            id="yaxis-fieldset"
            label="Y-Axis"
            label-for="yaxisselect"
          >
            <b-form-select id="yaxisselect" v-model="settings.yaxis" :options="axisOptions"></b-form-select>
          </b-form-group>
          <b-form-group
            id="xaxis-fieldset"
            label="X-Axis"
            label-for="xaxisselect"
          >
            <div v-for="(item, index) in settings.xaxisArray" class="d-flex my-1">
              <b-form-select
                :id="'xaxisselect2' + index"
                :key="index"
                v-model="item.xaxis"
                :options="axisOptions"
              ></b-form-select>
              <b-btn
                variant="outline-danger"
                class="btn-circle my-auto ml-1"
                @click="settings.xaxisArray.splice(index, 1)"
                size="sm"
              >
                <i class="fas fa-times"></i>
              </b-btn>
            </div>
            <b-btn variant="light" @click="addNewXAxis({})">
              + Add Axis Field
            </b-btn>
          </b-form-group>

          <b-form-group
            id="sort-fieldset"
            label="Sort By"
            label-for="sortselect"
          >
            <b-form-select id="sortselect" v-model="settings.sortBy" :options="axisOptions"></b-form-select>
          </b-form-group>

          <b-form-group
            id="sortdesc-fieldset"
            label="Sort Desc"
            label-for="sortdesccheckbox"
          >
            <div class="d-flex">
              <b-form-checkbox id="sortdesccheckbox" v-model="settings.sortDesc"></b-form-checkbox>
              <b-form-select id="sortdescselect" v-model="settings.cutoff" :options="cutoffOptions"></b-form-select>
            </div>
          </b-form-group>
        </div>

      </div>
    </div>
    <div id="chart-2"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs content-class="mt-3">
            <b-tab title="Raw (JSON)" active>
              <div class="mb-2">
                <b-btn
                  variant="primary"
                  class="shadow-sm d-none d-sm-inline-block">
                  <i class="fas fa-download fa-sm"></i>
                  Download
                </b-btn>
              </div>
              <pre>{{ data }}</pre>
            </b-tab>
            <b-tab title="Table (CSV)">
              <div class="mb-2">
                <b-btn
                  variant="primary"
                  class="shadow-sm d-none d-sm-inline-block"
                  @click="() => csvDownload(data)"
                >
                  <i class="fas fa-download fa-sm"></i>
                  Download
                </b-btn>
              </div>
              <b-table :items="data" hover :sort-by="sortBy" :sort-desc="sortDesc"></b-table>
            </b-tab>
            <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import csvDownload from "json-to-csv-export"
import { mapGetters, mapActions, mapMutations } from "vuex"
const Chartkick = require("chartkick")

export default {
  name: "Chartrenderer",
  props: {
    data: Array
  },
  data () {
    return {
      chartType: "LineChart",
      chartOptions: ["LineChart", "PieChart", "ColumnChart", "BarChart", "Timeline"],
      xaxis: null,
      yaxis: null,
      sortBy: null,
      sortDesc: false,
      processedData: [],
      ignoreDataChange: false,
      xaxisArray: []
    }
  },
  watch: {
    data (val, oldVal) {
      this.renderChart()
    },
    settings: {
      deep: true,
      handler (val, oldVal) {
        const { sortBy, sortDesc, xaxis, yaxis, xaxisArray } = val
        this.sortBy = sortBy
        this.sortDesc = sortDesc
        this.xaxisArray = xaxisArray
        this.yaxis = yaxis
        this.renderChart()
      }
    }
  },
  computed: {
    axisOptions () {
      if (!this.data || this.data.length === 0) return null
      return Object.keys(this.data[0])
    },
    ...mapGetters({
      settings: "squaapiStore/getQuerySettings"
    }),
    cutoffOptions () {
      return [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: "No", value: null }
      ]
    }
  },
  mounted () {
    this.renderChart()
  },
  methods: {
    ...mapMutations({ setSettings: "squaapiStore/setSettings" }),
    csvDownload,
    addNewXAxis (xaxis) {
      let newXAxisArray = this.settings.xaxisArray
      if (!newXAxisArray) newXAxisArray = []
      newXAxisArray.push(xaxis)
      this.$set(this.settings, "xaxisArray", newXAxisArray)
    },
    changeChart (chartType) {
      this.chartType = chartType
      this.renderChart()
    },
    sortData (unsortedData) {
      return unsortedData.sort((a, b) => {
        const sign = this.sortDesc ? -1 : 1
        // TODO: sorting for different data types: datetime, date, time, string, int
        let order = a[this.sortBy] > b[this.sortBy] ? 1 : -1
        if (a[this.sortBy] === null) order = 1
        if (b[this.sortBy] === null) order = -1
        return order * sign
      })
    },
    processData (data, yaxis = this.yaxis) {
      return data.map(item => {
        const xValue = this.settings.xaxisArray
          ? this.settings.xaxisArray.map((xaxis) => {
            return !item[xaxis.xaxis] ? null : "" + item[xaxis.xaxis]
          }).filter(item => item).join(" ")
          : null
        return [xValue, item[yaxis]]
      })
    },
    renderChart (data = null) {
      if (!data) data = this.data

      if (this.ignoreDataChange) return
      if (!data || data.length === 0) return
      this.ignoreDataChange = true

      if (this.sortBy) data = this.sortData(data)

      if (this.xaxisArray && this.yaxis) {
        this.processedData = this.processData(data)
        if (this.settings.cutoff) this.processedData = this.processedData.slice(0, this.settings.cutoff)
        new Chartkick[this.chartType]("chart-1", this.processedData)
      }

      this.$nextTick(() => {
        // we ignore any data changes until the chart is rendered
        // to not end up in an endless loop and save performance
        this.ignoreDataChange = false
      })
    }
  }
}
</script>
