<template>
  <div class="card border-left-info shadow h-100">
    <div class="card-header">
      <div class="d-sm-flex align-items-center justify-content-between w-100">
        <div class=" font-weight-bold text-secondary text-uppercase">
          Aggregate
        </div>
      </div>
    </div>

    <div class="card-body d-flex">
      <div v-for="(val, index) in aggregates" :key="index" class="m-1">
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-btn variant="outline-secondary" disabled>{{ val.field.field }}</b-btn>
          </b-input-group-prepend>
          <b-form-select
            class="h-100"
            v-model="val.aggregateFunction"
            :options="getAggregateFunctions(val.field)"
            @change="change(val)"
          ></b-form-select>
          <b-input-group-append>
            <button type="button" class="btn btn-outline-danger btn-sm" @click="remove(val)">x</button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </div>
</template>
<script>
import { getAggregateFunctions } from "./utils"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AggregationCard",
  computed: {
    ...mapGetters({ aggregates: "squaapiStore/getQueryAggregates" })
  },
  methods: {
    ...mapActions({
      change: "squaapiStore/modifyAggregate",
      remove: "squaapiStore/removeAggregate"
    }),
    getAggregateFunctions
  }
}
</script>
