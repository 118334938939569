<template>
  <div class="card border-left-success shadow h-100">
    <div class="card-header">
      <div class=" font-weight-bold text-secondary text-uppercase">
        Active Fields
      </div>
    </div>

    <!-- Fields -->
    <div class="card-body container">
      <div v-for="(fieldObject, index) in activeFields" :key="fieldObject.field" class="my-1">
        <b-btn variant="light" disabled>{{ fieldObject.field }}</b-btn>
        <b-btn @click="removeField(fieldObject)" variant="outline-danger" class="btn-circle">
          <i class="fas fa-times"></i>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "ActiveFieldsCard",
  computed: {
    ...mapGetters({
      activeFields: "squaapiStore/getQueryFields"
    })
  },
  methods: {
    ...mapActions({
      removeField: "squaapiStore/removeField"
    })
  }
}
</script>

<style scoped lang="scss">
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
</style>
