<template>
  <div class="input-group mb-3">
    <b-input-group size="sm">
      <b-input-group-prepend>
        <b-btn variant="outline-secondary" disabled>{{ filter.field.field }}</b-btn>
      </b-input-group-prepend>

      <b-form-select class="h-100" v-model="filter.filterFunction" :options="getFilterFunctions(filter.field)"></b-form-select>
      <b-form-datepicker v-if="filter.type === 'date'" id="example-datepicker" v-model="filter.filterValue"
                         style="flex-wrap: nowrap; white-space: nowrap">
      </b-form-datepicker>
      <input v-else type="text" class="form-control btn-sm" v-model="filter.filterValue" aria-label=""
             aria-describedby="basic-addon1">

      <b-input-group-append>
        <b-form-checkbox v-model="filter.active" class="my-auto m-1" switch></b-form-checkbox>
        <button type="button" class="btn btn-outline-danger btn-sm" @click="$emit('delete')">x</button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { getFilterFunctions } from "./utils"
import { mapGetters } from "vuex"

export default {
  name: "FilterCell",
  props: {
    filter: Object
  },
  watch: {
    filter: {
      deep: true,
      handler (val, oldVal) {
        this.$emit("change", this.filter)
      }
    }
  },
  computed: {
    ...mapGetters({ getFilterFunctions: "squaapiStore/getFilterFunctions" })
  }
}
</script>

<style scoped lang="scss">

</style>
