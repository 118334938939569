<template>
  <div id="wrapper" class="scrollview">
    <div id="content-wrapper" class="d-flex flex-column">

      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-3 static-top shadow">
          <!-- Topbar Search -->
          <div class="d-sm-flex align-items-center justify-content-between w-100">
            <div class="d-sm-flex align-items-center">
              <h1 class="h3 mb-0 text-gray-800 mr-3">Dashboard - </h1>

              <div class="btn-group">
                <b-dropdown
                  split
                  :text="activeModel ? activeModel.label : 'None'"
                  class="m-2 disabled"
                  variant="primary"
                >
                  <b-dropdown-item v-for="(model, index) in availableModels"
                                   :key="index"
                                   @click="selectModel(model)"
                                   href="#"
                  >
                    {{ model.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div class="btn-group">
                <b-dropdown
                  split
                  :text="!!activeQuery ? activeQuery.name : 'Load query'"
                  class="m-2 disabled"
                  variant="primary"
                  @click="loadQuery(activeQuery)"
                >
                  <b-dropdown-item v-for="(query, index) in availableQueries"
                                   :key="index"
                                   @click="loadQuery(query)"
                                   href="#"
                  >
                    {{ query.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div class="ml-2 d-flex">
                <b-input
                  type="text"
                  placeholder="Query Name"
                  class="my-auto"
                  v-model="queryName"
                ></b-input>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="saveQuery({name: queryName, model: activeModel})"
                >
                  Save Query
                </button>
              </div>
            </div>
            <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
              class="fas fa-download fa-sm text-white-50"></i> Download</a>
          </div>
        </nav>
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid">
          <div class="row">

            <!-- Left Column - Available Fields -->
            <div class="col-xl-3 col-md-4 mb-3">
              <FieldCard :availableFields="modelFields" ref="fieldcard"/>
            </div>

            <div class="col-xl-9 col-md-8">
              <div>
                <b-tabs content-class="mt-3">
                  <b-tab title="New Query" active @click="resetState">
                    <!-- Controls Row -->
                    <div class="row">
                      <!-- Active Fields Card -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <ActiveFieldsCard ref="activefieldscard"/>
                      </div>

                      <!-- Filter Card -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <FilterCard ref="filtercard"/>
                      </div>

                      <!-- Aggregations -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <AggregationCard ref="aggregationcard"/>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="query.name"
                    v-for="(query) in loadedQueries"
                    :key="query.id"
                    @click="activateQuery(query)"
                    :active="query === activeQuery"
                  >
                    <!-- Controls Row -->
                    <div class="row">
                      <!-- Active Fields Card -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <ActiveFieldsCard ref="activefieldscard"/>
                      </div>

                      <!-- Filter Card -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <FilterCard ref="filtercard"/>
                      </div>

                      <!-- Aggregations -->
                      <div class="col-xl-4 col-md-6 mb-3">
                        <AggregationCard ref="aggregationcard"/>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab title="Combinator" @click="combineQueries"></b-tab>

                </b-tabs>
              </div>

              <!-- Controls Row -->
              <div class="row" v-if="false">
                <!-- Active Fields Card -->
                <div class="col-xl-4 col-md-6 mb-3">
                  <ActiveFieldsCard ref="activefieldscard"/>
                </div>

                <!-- Filter Card -->
                <div class="col-xl-4 col-md-6 mb-3">
                  <FilterCard ref="filtercard"/>
                </div>

                <!-- Aggregations -->
                <div class="col-xl-4 col-md-6 mb-3">
                  <AggregationCard ref="aggregationcard"/>
                </div>
              </div>
              <!-- End Upper Card Row-->

              <!-- Chart Row -->
              <div class="row">
                <!-- Chart + Table -->
                <div class="col-xl-12 col-lg-12">
                  <ChartRenderer :data='queryData'/>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- End Page Content -->

      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Benedikt Reschberger - reschberger@gmail.com - 2020</span>
          </div>
        </div>
      </footer>
      <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->
  </div>
</template>
<script>
import ChartRenderer from "./Dashboard/ChartRenderer.vue"
import FilterCard from "./Dashboard/FilterCard.vue"
import FieldCard from "./Dashboard/FieldCard"
import AggregationCard from "./Dashboard/AggregateCard"
import ActiveFieldsCard from "./Dashboard/ActiveFieldsCard"
import { mapActions, mapGetters, mapMutations } from "vuex"

/*
* external dependencies:
* - momentjs
* - axios
* - Chartkick
* - Google Charts (can also use different library)
* - vue bootstrap (but we can easily override any styling using customized bootstrap css)
* */

export default {
  components: { ActiveFieldsCard, AggregationCard, FieldCard, ChartRenderer, FilterCard },
  name: "DRFCharts",
  data () {
    return {
      availableModels: [
        { label: "Calls", model: "calls_call" },
        { label: "Call Items", model: "calls_callitem" },
        { label: "Counterpart", model: "counterparts_counterpart" },
        { label: "Users", model: "users_user" },
        { label: "Playbooks", model: "talkscripts_talkscript" },
        { label: "Answers", model: "talkscripts_answer" }
      ],
      queryName: ""
    }
  },
  computed: {
    ...mapGetters({
      modelFields: "squaapiStore/getModelFields",
      activeModel: "squaapiStore/getModel",
      queryData: "squaapiStore/getQueryData",
      availableQueries: "squaapiStore/getSavedQueries",
      activeQuery: "squaapiStore/getQuery",
      loadedQueries: "squaapiStore/getLoadedQueries"
    })
  },
  mounted () {
    this.selectModel(this.availableModels[0])
    this.retrieveQueries()
  },
  methods: {
    ...mapActions({
      loadModelFields: "squaapiStore/loadModelFields",
      addField: "squaapiStore/addField",
      removeFieldFromStore: "squaapiStore/removeField",
      retrieveQueries: "squaapiStore/retrieveQueries",
      saveQuery: "squaapiStore/saveQuery",
      loadQueryInternal: "squaapiStore/loadQuery",
      activateQuery: "squaapiStore/activateQuery",
      combineQueries: ""
    }),
    ...mapMutations({
      setModel: "squaapiStore/setModel",
      resetState: "squaapiStore/resetState"
    }),
    loadQuery (query) {
      if (!query) return
      this.loadQueryInternal(query)
    },
    selectModel (selected) {
      this.setModel(selected)
      this.loadModelFields(selected.model)
    }
  }
}

</script>
<style scoped lang="scss">

.scrollview {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
