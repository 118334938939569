export const getFilterFunctions = field => {
  return field.lookups
}

export const getAggregateFunctions = field => {
  return [
    { text: "Sum", value: "Sum" },
    { text: "Count", value: "Count" },
    { text: "Count Distinct", value: "CountDistinct" },
    { text: "Average", value: "Avg" }
  ]
}
