<template>
  <div class="d-flex justify-content-between">
    <div class="m-1">
      <b-form-select class="h-100" v-model="aggregateFunction" :options="availableAggregations"></b-form-select>
    </div>
    <div class="m-1 my-auto">
      <b-btn variant="primary" class="btn-circle my-auto" size="sm"
             @click="$emit('input', {aggregateFunction})">
        <i class="fas fa-check"></i>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { getAggregateFunctions } from "./utils"

export default {
  name: "AggregationCreateForm",
  props: {
    field: Object
  },
  data () {
    return {
      availableAggregations: getAggregateFunctions(this.field),
      aggregateFunction: null
    }
  }
}
</script>

<style scoped lang="scss">

</style>
