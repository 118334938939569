<template>
  <div class="card border-left-secondary shadow h-100">
    <div class="card-header">
      <div class=" font-weight-bold text-secondary text-uppercase">
        Model Fields
      </div>
    </div>

    <!-- Fields -->
    <div class="card-body container">
      <FieldCell
        v-for="(val, index) in availableFields"
        :field="val"
        :key="index"
        :index="val.field + index"
      ></FieldCell>
    </div>
  </div>
</template>
<script>
import FieldCell from "./FieldCell"

export default {
  name: "FieldCard",
  components: { FieldCell },
  props: {
    availableFields: Array
  }
}
</script>
