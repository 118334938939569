<template>
  <div
    class="w-100 btn btn-light my-1"
    style="padding-right: 4px"
  >
    <div class="row"
         @click="getFields"
    >
      <div class="col-1">
        <i
          v-if="hasLookups || lookupIsString"
          class="fas"
          :class="showFields ? 'fa-minus' : 'fa-plus'"
        ></i>
      </div>
      <div class="col d-flex justify-content-between">
        <div class="my-auto">
          {{ getLabel(field) }}
        </div>
        <div v-if="field.type !== 'relation'">

          <!-- Group_by -->
          <button
            v-if="!isInQuery"
            :id="'add-active-' + index"
            type="button"
            class="btn btn-outline-success btn-circle mr-1"
            @click.stop="addField(field)"
            v-b-tooltip.hover.left="'Add to active fields'"
          >
            <i class="fas fa-plus"></i>
          </button>

          <!-- Filter -->
          <button
            :id="'add-filter-' + index"
            type="button"
            class="btn btn-primary btn-circle mr-1"
            @click.stop="() => {field.showFilterCreate = true}"
            v-b-tooltip.hover.top="'Add a new filter'"
          >
            <i class="fas fa-filter"></i>
          </button>
          <b-popover
            :target="'add-filter-' + index"
            variant="default"
            triggers="focus"
            boundary="viewport"
            custom-class="wide-popover"
            :show.sync="field.showFilterCreate"
          >
            <template #title>Add Filter</template>
            <FilterCreateForm
              :field="field"
              @input="args => handleNewFilter(args, field)"
            ></FilterCreateForm>
          </b-popover>

          <!-- Aggregations -->
          <button
            type="button"
            class="btn btn-outline-info btn-circle"
            :id="'add-aggregation-' + index"
            @click.stop="field.showAggregationCreate = true"
            v-b-tooltip.hover.top="'Add a new aggregation'"
          >
            <i class="fas fa-chart-pie"></i>
          </button>
          <b-popover
            :target="'add-aggregation-' + index"
            variant="default"
            triggers="focus"
            boundary="viewport"
            custom-class="wide-popover"
            :show.sync="field.showAggregationCreate"
          >
            <template #title>Add Aggregation</template>
            <AggregationCreateForm
              :field="field"
              @input="args => handleNewAggregate(args, field)"
            ></AggregationCreateForm>
          </b-popover>

        </div>
      </div>
    </div>

    <!-- collapse -->
    <b-collapse :id="'child-field-collapse-' + field.field"
                class="mt-1 w-100"
                style="margin-left: 5px;"
                v-model="showFields"
    >
      <div v-if="loading" class="my-2">Loading...</div>
      <FieldCell
        v-for="(v, index) in field.lookupFields" :field="v" :key="index" :index="'' + v.field + index"
      ></FieldCell>
    </b-collapse>

  </div>
</template>

<script>
import FilterCreateForm from "./FilterCreateForm"
import AggregationCreateForm from "./AggregationCreateForm"
import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "FieldCell",
  components: { AggregationCreateForm, FilterCreateForm },
  props: {
    field: Object,
    index: String
  },
  data () {
    return {
      lookupFields: [],
      loading: false,
      showFields: false
    }
  },
  computed: {
    ...mapGetters({
      getActiveFields: "squaapiStore/getQueryFields"
    }),
    isInQuery () {
      if (this.getActiveFields.length === 0) return false
      return this.getActiveFields.filter(i => i.field === this.field.field).length > 0
    },
    hasLookups () {
      return this.field.lookupFields && this.field.lookupFields.length > 0
    },
    lookupIsString () {
      return typeof this.field.fields === "string"
    }
  },
  mounted () {
    this.$set(this.field, "lookupFields", this.getTransforms(this.field))
  },
  methods: {
    ...mapActions({
      addField: "squaapiStore/addField",
      addFilter: "squaapiStore/addFilter",
      addAggregate: "squaapiStore/addAggregate"
    }),
    handleNewAggregate (args, field) {
      this.addAggregate({ ...args, field, active: true })
      this.$set(field, "showAggregationCreate", false)
    },
    handleNewFilter (args, field) {
      this.addFilter({ ...args, field, active: true })
      this.$set(field, "showFilterCreate", false)
    },
    getParam (f1, f2) {
      return f1 + "__" + f2
    },
    getTransforms (field) {
      // TODO: move this into the utils file / store
      if (field.transforms && Array.isArray(field.transforms)) {
        return field.transforms.map(text => {
          return { field: this.getParam(this.field.field, text), type: text }
        })
      }
      return []
    },
    getFields () {
      if (!this.hasLookups && !this.lookupIsString) return
      // TODO: move into utils file / store
      this.showFields = !this.showFields
      if (this.lookupFields.length > 0) return
      if (typeof this.field.fields !== "string") return
      this.loading = true
      axios.get(this.field.fields).then(response => {
        this.loading = false
        const newField = response.data.map(item => {
          item.field = this.getParam(this.field.field, item.field)
          return item
        })
        this.$set(this.field, "lookupFields", newField)
      })
    },
    getLabel (field) {
      const fieldSplit = field.field.split("__")
      return fieldSplit[fieldSplit.length - 1]
    }
  }
}
</script>

<style scoped lang="scss">
.wide-popover {
  max-width: 500px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
</style>
