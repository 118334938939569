<template>
  <div class="d-flex justify-content-between">
    <div class="m-1">
      <b-form-select
        class="h-100"
        v-model="filterFunction"
        :options="getFilterFunctions(field)"
      ></b-form-select>
    </div>
    <div class="m-1">
      <b-form-datepicker
        v-if="isDatetime"
        id="example-datepicker"
        v-model="filterValue"
        style="flex-wrap: nowrap; white-space: nowrap"
      >
      </b-form-datepicker>
      <input
        v-else
        type="text"
        class="form-control btn-sm"
        v-model="filterValue"
        aria-label=""
        aria-describedby="basic-addon1"
      >
    </div>
    <div class="m-1 my-auto">
      <b-btn
        variant="primary"
        class="btn-circle my-auto"
        size="sm"
        @click="createFilter"
      >
        <i class="fas fa-check"></i>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { getFilterFunctions } from "./utils"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "FilterCreateForm",
  props: {
    field: Object
  },
  computed: {
    isDatetime () {
      return this.field.type.indexOf("date") >= 0
    },
    dbField () {
      return this.field.field
    },
    ...mapGetters({ getFilterFunctions: "squaapiStore/getFilterFunctions" })
  },
  methods: {
    createFilter () {
      this.$emit("input", {
        filterValue: this.filterValue,
        filterFunction: this.filterFunction
      })
    }
  },
  data () {
    return {
      filterValue: null,
      filterFunction: null
    }
  }
}
</script>

<style scoped lang="scss">

</style>
